import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  startOfISOWeek, addDays, format, getISOWeek,
} from 'date-fns';
import styled from 'styled-components';
import {
  Label, LabelList, PieChart, Pie, Cell,
} from 'recharts';
import { initializeReports, resetReports } from '../reducers';
import PlanForm from './PlanForm';

const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .selectDiv {
    width: 100%;
    display: grid;
    grid-template-columns: 1000px 360px;
    grid-gap: 80px;
    justify-content: space-between;

    .recharts-text.font-large {
      font-size: 30px;
    };

    .font-white {
      fill: #FFFFFF;
    };
  };

  & > .table {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 8px gray;
    
    & > .tableTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
    };

    & > .tableHeader {
      display: grid;
      grid-template-columns: 208px repeat(11, 112px);
      border-bottom: 4px solid lightgray;

      & > div {
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;
        box-sizing: border-box;
        padding: 5px 8px;
        font-size: 18px;
      };
    };

    & > .tableBody {
      & > .planRow > div > button {
        border: none;
        background-color: #1976D2;
        border-radius: 4px;
        color: #FFFFFF;

        &:hover {
          cursor: pointer;
        };
      };

      & > .row:hover {
        cursor: default;
        background-color: lightblue;
        font-weight: bold;
      };

      & > .row > div {
        border-right: 1px solid lightgray;
      };

      & > .row > .toolTip {
        position: relative;

        & > .toolTipText {
          visibility: hidden;
          width: 80px;
          background-color: black;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 1;
          bottom: 70%;
          left: 50%;
          margin-left: -40px;

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
          };
        };

        &:hover > .toolTipText {
          visibility: visible;
        };
      };

      & > .planRow, > .row {
        display: grid;
        grid-template-columns: 208px repeat(11, 112px);
        border-bottom: 1px solid lightgray;
        box-sizing: border-box;

        & > div {
          height: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: right;
          box-sizing: border-box;
          padding: 5px 8px;
          font-size: 14px;
        };

        & > div:first-child {
          justify-content: left;
          border-left: none;
        };
      };
    };
  };
`;

const RadioGroup = styled.div`
  width: fit-content;
  box-shadow: inset 0 0 4px gray;
  border-radius: 4px;
  position: relative;
  overflow: hidden; /* Hide overflow part of the blue slide bar when first time render the page */
  font-size: 14px ;

  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    display:none;

    &:checked+label {
      color: #FFFFFF;
      cursor: default;
      transition: color .5s ease;
    };

    // Color transformation from selected to un-selected
    &:not(:checked)+label {
      color: #000000;
      transition: color .5s ease;
    }
  };

  label {
    width: 100px;
    height: 16px;
    border-radius: 4px;
    padding: 8px 10px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
  };

  .slide {
    position: absolute;
    top: 0px;
    left: ${(props) => props.position * 120}px;
    width: 120px;
    height: 32px;
    border-radius: 4px;
    background-color: #1976D2;
    box-shadow: 1px 1px 4px black;
    transition: left 0.5s ease;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;

  & button {
    border: none;
    padding: 0px;
    background-color: transparent;
    text-shadow: 4px 4px 4px lightgray;
    color: #1976D2;
    font-size: 20px;
    transition: text-shadow .5s;

    &:not([disabled]):hover {
      cursor: pointer;
      color: #0D47A1;
      text-shadow: 2px 2px 2px gray;
      transition: text-shadow .5s;
    };

    &:disabled {
      color: gray;
    };
  };
`;

const Home = () => {
  const now = new Date();
  const dispatch = useDispatch();
  const {
    reports, customers, productTypes, products, auth,
  } = useSelector((state) => state);
  const [startDate, setStartDate] = useState(startOfISOWeek(now));
  const [customerId, setCustomerId] = useState('0');
  const [productTypeId, setProductTypeId] = useState('0');
  const [showPlan, setShowPlan] = useState(false);
  const [planWeekday, setPlanWeekday] = useState();
  const nextWeekMon = addDays(startDate, 7);
  const customer = customers.find((i) => i.id === +customerId);

  useEffect(() => {
    dispatch(resetReports());
    setShowPlan(false);
  }, [startDate, customerId, productTypeId]);

  const fetchButtonClicked = () => {
    dispatch(initializeReports(customerId, format(startDate, 'yyyy-MM-dd'), format(addDays(startDate, 6), 'yyyy-MM-dd'), productTypeId));
    setShowPlan(false);
  };

  const planButtonClicked = (weekday) => {
    setPlanWeekday(weekday);
    setShowPlan(!showPlan);
  };

  const data = [
    {
      name: 'Actual',
      value: Math.round(reports.summary.total_invoice_actual),
    },
    {
      name: 'Waste',
      value: Math.round(
        reports.summary.total_invoice_delivered - reports.summary.total_invoice_actual,
      ),
    },
  ];

  const colors = ['#36A2EB', '#FF6384'];

  return (
    <StyledHome>
      <div className="selectDiv">
        <div>
          <StyledRow>
            <div>
              <input
                id="date-picker"
                type="date"
                value={format(startDate, 'yyyy-MM-dd')}
                required
                step="7"
                onChange={({ target }) => target.value && setStartDate(new Date(target.value))}
              />
            </div>
            <button type="button" onClick={fetchButtonClicked} disabled={customerId === '0'}>Fetch</button>
          </StyledRow>
          <div>
            <label htmlFor="customerSelection">Customer</label>
            <select onChange={({ target }) => setCustomerId(target.value)} value={customerId}>
              <option value="0" disabled>Select</option>
              {customers
                .filter((item) => item.active)
                .map((i) => <option key={i.name} value={i.id}>{i.name}</option>)}
            </select>
          </div>
          <RadioGroup
            position={[{ id: 0, name: 'All' }].concat(productTypes).findIndex((productType) => productType.id === +productTypeId)}
          >
            {[{ id: 0, name: 'All' }].concat(productTypes).map((productType) => (
              <Fragment key={productType.id}>
                <input
                  id={productType.name}
                  type="radio"
                  name="productType"
                  value={productType.id}
                  checked={productType.id === +productTypeId}
                  onChange={({ target }) => setProductTypeId(target.value)}
                />
                <label htmlFor={productType.name}>{productType.name}</label>
              </Fragment>
            ))}
            <div className="slide" />
          </RadioGroup>
        </div>
        <div style={{ position: 'relative' }}>
          <PieChart width={360} height={360}>
            <Pie
              data={data}
              dataKey="value"
              startAngle={90}
              endAngle={450}
              innerRadius="40%"
            >
              {reports.summary.totalWastePercentage
                && <Label className="font-large" value={`${(reports.summary.totalWastePercentage).toFixed(2)}%`} position="center" />}
              {data.map((entry, index) => (
                <Cell key={`cell-${entry.name}`} fill={colors[index]} />
              ))}
              <LabelList
                className="font-white"
                dataKey="value"
                position="inside"
                formatter={(value) => `${value} €`}
              />
            </Pie>
          </PieChart>
        </div>
      </div>
      {customerId !== '0'
      && (
      <>
        <div className="table">
          <div className="tableTitle">
            <div>{customer?.name}</div>
            <div>{`Week ${getISOWeek(startDate)}`}</div>
            <div>{`${format(startDate, 'dd-MM-yyyy')} to ${format(addDays(startDate, 6), 'dd-MM-yyyy')}`}</div>
          </div>
          <div className="tableHeader">
            <div />
            {WEEKDAYS.map((weekday) => <div key={weekday}>{weekday}</div>)}
            <div>S/D</div>
            <div>Total €</div>
            <div>Waste</div>
            <div>Health</div>
          </div>
          <div className="tableBody">
            {customerId !== '0' && (!customer.latest || format(addDays(nextWeekMon, 5), 'yyyy-MM-dd') > customer.latest) && ( // When to display the row
            <div className="planRow">
              <div />
              {auth.user.role < 2
                && WEEKDAYS.slice(0, WEEKDAYS.length - 1).map((weekday, index) => {
                  const targetDate = format(addDays(nextWeekMon, index), 'yyyy-MM-dd');
                  if (customer.latest && targetDate <= customer.latest) {
                    return (
                      <div key={weekday} />
                    );
                  }
                  return (
                    <div key={weekday}>
                      <button
                        type="button"
                        onClick={() => planButtonClicked(index)}
                      >
                        {`Plan ${targetDate.split('-').slice(1).join('-')}`}
                      </button>
                    </div>
                  );
                })}
            </div>
            )}
            {reports.data.length > 0
            && reports.data.slice(0, reports.data.length - 1).map((row, rowIndex) => (
              <div className="row" key={row.product.name}>
                <div>{row.product.name}</div>
                {WEEKDAYS.map((weekday, index) => (
                  <div key={`${weekday}-${row.product.name}`}>{`${row.product.sold[index]}/${row.product.delivered[index]}`}</div>
                ))}
                {row.product.name === 'Total Unit'
                  ? <div>{`${reports.summary.total_unit_sold}/${reports.summary.total_unit_delivered}`}</div>
                  : (row.unit_sold || row.unit_delivered)
                    && (
                    <div className="toolTip">
                      {`${row.unit_sold}/${row.unit_delivered}`}
                      <span className="toolTipText">{`${row.product.total_sold} / ${row.product.total_delivered}`}</span>
                    </div>
                    )}
                <div>{(row.invoice_actual || row.invoice_delivered) && `${Math.round(row.invoice_actual)}/${Math.round(row.invoice_sold)}/${Math.round(row.invoice_delivered)}`}</div>
                <div>{row.waste}</div>
                <div className="toolTip">
                  {reports.data.length - rowIndex > 3 && `${row.product.avg_performance || 'N/A'} / ${products.find((item) => item.id === row.product.id).avg_performance}`}
                  {reports.data.length - rowIndex > 3 && <span className="toolTipText">{`AVG: ${row.product.avg_sold || 'N/A'}`}</span>}
                </div>
              </div>
            ))}
            {reports.data.length > 0
            && (
            <div className="row">
              <div>Waste</div>
              {reports.data[reports.data.length - 1]?.product.waste.map((item, index) => (
                <div key={`${customer?.name}-waste-${WEEKDAYS[index]}`}>{item}</div>
              ))}
            </div>
            )}
          </div>
        </div>
        {showPlan && (
        <PlanForm
          week={+getISOWeek(startDate)}
          customerId={customerId}
          data={reports.data}
          planWeekday={planWeekday}
          targetDate={format(addDays(nextWeekMon, planWeekday), 'yyyy-MM-dd')}
          setShowPlan={setShowPlan}
        />
        )}
      </>
      )}
    </StyledHome>
  );
};

export default Home;
