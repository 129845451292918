import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter, Routes, Route, Navigate, NavLink,
} from 'react-router-dom';

import {
  Products, Customers, Plan, Login,
  LogOut, Profile, Users, Modal, Files, Dashboard, Edit,
} from './components';

import {
  initializeProductTypes,
  initializeProducts,
  initializeInvoiceTypes,
  initializeGroups,
  initializeCustomers,
  initializeUsers,
  initializeDashboard,
  checkLogin,
} from './reducers';

import socket from './services/socket';
import { appendCustomer, modifyCustomer, removeCustomer } from './reducers/customer';
import { setNotification, resetNotification } from './reducers/notification';
import { appendGroup, modifyGroup, removeGroup } from './reducers/group';
import { appendProduct, modifyProduct, removeProduct } from './reducers/product';
import { appendProductType, modifyProductType, removeProductType } from './reducers/productType';

import {
  StyledApp, ErrorMessage, SuccessMessage, SocketMessage,
} from './components/styles';

const App = () => {
  const dispatch = useDispatch();
  const { status, user } = useSelector((state) => state.auth);
  const { notification } = useSelector((state) => state);
  const { isOpen } = useSelector((state) => state.modal);

  useEffect(() => {
    if (status === 'unCheck') {
      dispatch(checkLogin());
    }

    // Initialize all data at the beginning.
    if (status === 'pass') {
      dispatch(initializeDashboard());
      dispatch(initializeCustomers());
      dispatch(initializeProductTypes());
      dispatch(initializeProducts());
      dispatch(initializeInvoiceTypes());
      dispatch(initializeGroups());
      // dispatch(initializeRecords());
      if (user.role < 2) {
        dispatch(initializeUsers());
      }
    }
  }, [dispatch, status]);

  useEffect(() => {
    const showNotification = (category, data, type) => {
      dispatch(setNotification({ type: 'notification', content: `${category} ${data.name} has been ${type} by another user` }));
      setTimeout(() => {
        dispatch(resetNotification());
      }, 3000);
    };

    socket.on('add_customer', (data) => {
      dispatch(appendCustomer(data));
      showNotification('Customer', data, 'added');
    });

    socket.on('add_group', (data) => {
      dispatch(appendGroup(data));
      showNotification('Group', data, 'added');
    });

    socket.on('add_product', (data) => {
      dispatch(appendProduct(data));
      showNotification('Product', data, 'added');
    });

    socket.on('add_type', (data) => {
      dispatch(appendProductType(data));
      showNotification('Product Type', data, 'added');
    });

    socket.on('delete_customer', (data) => {
      dispatch(removeCustomer(data));
      showNotification('Customer', data, 'deleted');
    });

    socket.on('delete_group', (data) => {
      dispatch(removeGroup(data));
      showNotification('Group', data, 'deleted');
    });

    socket.on('delete_product', (data) => {
      dispatch(removeProduct(data));
      showNotification('Product', data, 'deleted');
    });

    socket.on('delete_type', (data) => {
      dispatch(removeProductType(data));
      showNotification('Product Type', data, 'deleted');
    });

    socket.on('update_customer', (data) => {
      dispatch(modifyCustomer(data));
      showNotification('Customer', data, 'updated');
    });

    socket.on('update_group', (data) => {
      dispatch(modifyGroup(data));
      showNotification('Group', data, 'updated');
    });

    socket.on('update_product', (data) => {
      dispatch(modifyProduct(data));
      showNotification('Product', data, 'updated');
    });

    socket.on('update_type', (data) => {
      dispatch(modifyProductType(data));
      showNotification('Product type', data, 'updated');
    });
  }, [socket]);

  if (status === 'unCheck') {
    return <div />;
  }

  if (status === 'fail') {
    return (
      <>
        {notification.type === 'error' && <ErrorMessage>{notification.content}</ErrorMessage>}
        <Login />
      </>
    );
  }

  return (
    <StyledApp>
      {notification.type === 'notification' && <SocketMessage>{notification.content}</SocketMessage>}
      {notification.type === 'error' && <ErrorMessage>{notification.content}</ErrorMessage>}
      {notification.type === 'success' && <SuccessMessage>{notification.content}</SuccessMessage>}
      <BrowserRouter>
        {/* Navigation bar */}
        <div className="navi-bar">
          <NavLink to="/">Dashboard</NavLink>
          <NavLink to="/plan">Plan</NavLink>
          { user.role < 2
            && (
              <>
                <NavLink to="/edit">Edit</NavLink>
                <NavLink to="/files">Files</NavLink>
                <NavLink to="/products">Products</NavLink>
                <NavLink to="/customers">Customers</NavLink>
                <NavLink to="/users">Users</NavLink>
              </>
            )}
          <NavLink to="/profile">Profile</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </div>

        <div className="routes">
          <Routes>
            { user.role < 2
              && (
                <>
                  <Route path="/edit" element={<Edit />} />
                  <Route path="/files" element={<Files />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/users" element={<Users />} />
                </>
              )}
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/logout" element={<LogOut />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </BrowserRouter>
      {isOpen && <Modal />}
    </StyledApp>
  );
};

export default App;
