import { createSlice } from '@reduxjs/toolkit';
import { getAllProducts } from '../services/product';

const productSlice = createSlice({
  name: 'products',
  initialState: [],
  reducers: {
    setProducts(state, action) {
      return action.payload;
    },
    appendProduct(state, action) {
      state.push(action.payload);
    },
    modifyProduct(state, action) {
      return state
        .map((item) => (
          item.id === action.payload.id
            ? { ...item, ...action.payload }
            : item
        ));
    },
    removeProduct(state, action) {
      return state
        .filter((item) => item.id !== action.payload.id);
    },
  },
});

export const {
  setProducts, appendProduct, modifyProduct, removeProduct,
} = productSlice.actions;

export const initializeProducts = () => async (dispatch) => {
  const products = await getAllProducts();
  dispatch(setProducts(products));
};

export default productSlice.reducer;
