import styled from 'styled-components';

const StyledToggleGroup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > .switch {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 40px;

    & > input {
      opacity: 0;
      width: 0;
      height: 0;
    };

    & > .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #CCCCCC;
      border-radius: 34px;
      transition: .4s;

      &::before {
        position: absolute;
        content: '';
        height: 32px;
        width: 32px;
        left: 4px;
        bottom: 4px;
        background-color: #FFFFFF;
        border-radius: 50%;
        transition: .4s;
      };
    };

    & > input {
      &:checked + .slider {
        background-color: #2196F3;
        box-shadow: 0 0 8px #2196F3;
      };

      &:checked + .slider:before {
        transform: translateX(78px);
      }
    };
  };
`;

export default StyledToggleGroup;
