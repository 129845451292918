import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setNotification, resetNotification } from '../reducers/notification';
import { changePassword } from '../services/auth';
import InputGroup from './InputGroup';
import { StyledProfile, UpdateButton, Loading } from './styles';

const Profile = () => {
  const dispatch = useDispatch();
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const validationSchema = Yup.object({
    currentPassword: Yup
      .string()
      .required('Current password required'),
    newPassword: Yup
      .string()
      .min(8, 'At least 8 characters')
      .required('New password required'),
    confirmNewPassword: Yup
      .string()
      .required('Type your new password again')
      .oneOf([Yup.ref('newPassword'), null], 'Password must match'),
  });

  const updateButtonClicked = async (values, { setSubmitting, resetForm }) => {
    const data = {
      password: values.currentPassword,
      newPassword: values.newPassword,
    };

    const response = await changePassword(data);

    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
    } else {
      dispatch(setNotification({ type: 'success', content: response.message }));
    }

    resetForm();
    setSubmitting(false);
    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  return (
    <StyledProfile>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={updateButtonClicked}
      >
        {({
          isValid, dirty, handleSubmit, isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <InputGroup id="currentPassword" type="password" name="currentPassword" text="Current password" />
            </div>
            <div>
              <InputGroup id="newPassword" type="password" name="newPassword" text="New password" />
            </div>
            <div>
              <InputGroup id="confirmNewPassword" type="password" name="confirmNewPassword" text="Confirm New password" />
            </div>
            <div>
              <UpdateButton className="updateButton" type="submit" disabled={isSubmitting || !isValid || !dirty}>
                {isSubmitting ? <Loading /> : 'Update'}
              </UpdateButton>
            </div>
          </form>
        )}
      </Formik>
    </StyledProfile>
  );
};

export default Profile;
