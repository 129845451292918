import styled, { keyframes } from 'styled-components';

const showAndDisappear = keyframes`
  0% {
    opacity: 0;
    top: 0%;
  };
  25% {
    opacity: 1;
    top: 50px;
  };
  50% {
    opacity: 1;
    top: 50px;
  };
  75% {
    opacity: 1;
    top: 50px;
  };
  100% {
    opacity: 0;
    top: 0%;
  };
`;

const Message = styled.div`
  position: fixed;
  z-index: 3;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 480px;
  height: 60px;
  font-size: 24px;
  
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  animation: ${showAndDisappear} 3s;
`;

const SuccessMessage = styled(Message)`
  background-color: #00d300;
`;

const ErrorMessage = styled(Message)`
  background-color: #FF465A;
`;

const SocketMessage = styled(Message)`
  background-color: #F15BB5;
`;

export {
  SuccessMessage,
  ErrorMessage,
  SocketMessage,
};
