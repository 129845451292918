import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, FieldArray, Field,
} from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import { setNotification, resetNotification } from '../reducers/notification';
import {
  getRecordsToEdit, deleteRecordsOfDay, updateRecordsOfDay, autoMakePlan,
} from '../services/records';
import InputGroup from './InputGroup';
import SelectGroup from './SelectGroup';
import {
  StyledEdit, ConfirmButton, CancelButton, UpdateButton, DeleteButton, Loading, SpecialButton,
} from './styles';

const Edit = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.filter((customer) => customer.active));
  const products = useSelector((state) => state.products.filter((product) => product.active));
  const { productTypes } = useSelector((state) => state);
  const [order, setOrder] = useState({ date: '', customerId: 0 });
  const [rawRecords, setRawRecords] = useState([]);
  const [recordsToDelete, setRecordsToDelete] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [autoMaking, setAutoMaking] = useState(false);

  const initialValues = { ...order };

  const validationSchema = Yup.object({
    date: Yup
      .date()
      .required(),
    customerId: Yup
      .string()
      .notOneOf(['0'])
      .required(),
  });

  const recordsValidationSchema = Yup.object().shape({
    records: Yup
      .array()
      .of(
        Yup.object().shape({
          quantity_delivery: Yup.number().required().min(0),
        }),
      )
      .required()
      .min(1),
  });

  const fetchRecords = async (values, { setSubmitting }) => {
    setRawRecords([]);
    const { date, customerId } = values;
    const records = await getRecordsToEdit(date, customerId);
    if (records.length === 0) {
      dispatch(setNotification({ type: 'error', content: 'No records!' }));
      setTimeout(() => {
        dispatch(resetNotification());
      }, 3000);
    }
    setOrder(values);
    setRawRecords(records);
    setSubmitting(false);
  };

  const recordsInitialValues = {
    records: rawRecords
      .sort((a, b) => a.product_id - b.product_id)
      .map((record) => ({ ...record })),
  };

  const deleteButtonClicked = (remove, record, index) => {
    if (record.id) {
      // The record is an existing record
      setRecordsToDelete(recordsToDelete.concat(record));
    }
    remove(index);
  };

  const changeSelect = (e, push) => {
    const productId = e.target.value;
    // If the productIdToAdd === 0, do nothing;
    if (productId === '0') {
      return null;
    }

    // If the productIdToAdd is in recordsToDelete array
    // move it to the array to show from array to delete
    const record = recordsToDelete.find((item) => item.product_id === +productId);
    if (record) {
      push(record);
      setRecordsToDelete(recordsToDelete.filter((item) => item.product_id !== +productId));
      return null;
    }

    const product = products.find((item) => item.id === +productId);
    const newRecord = {
      date: order.date,
      customer_id: +order.customerId,
      product_id: product.id,
      product: product.name,
      product_type_id: product.productTypeId,
      // By default, a new record sold is 0, but it will be further handled in the backend router
      // to check if customer is K or S style.
      // For K-style customer, backend router will replace quantity_sold to quantity_delivery
      // and set the corresponding invoice_amount.
      quantity_sold: 0,
      quantity_delivery: 1,
      invoice_amount: 0,
      sold_amount: 0,
      unit_price_by_order: product.unitPriceByOrder,
      unit_price_by_deliver: product.unitPriceByDeliver,
    };
    push(newRecord);
    return null;
  };

  const updateRecords = async (values, { setSubmitting }) => {
    const response = await updateRecordsOfDay(order.customerId, {
      update: values.records,
      delete: recordsToDelete,
    });

    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
    } else {
      dispatch(setNotification({ type: 'success', content: response.message }));
    }

    setSubmitting(false);
    setOrder({ date: '', customerId: 0 });
    setRawRecords([]);
    setRecordsToDelete([]);
    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  const deleteAll = async () => {
    setShowModal(false);
    setOrder({ date: '', customerId: 0 });
    setRawRecords([]);
    setRecordsToDelete([]);

    const response = await deleteRecordsOfDay(order.date, order.customerId);

    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
    } else {
      dispatch(setNotification({ type: 'success', content: response.message }));
    }

    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  const sameArray = (first, second) => {
    if (JSON.stringify(first) !== JSON.stringify(second)) {
      return false;
    }

    return true;
  };

  const autoMakePlanButtonClicked = async () => {
    setAutoMaking(true);
    const response = await autoMakePlan();
    setAutoMaking(false);

    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
    } else {
      dispatch(setNotification({ type: 'success', content: response.message }));
    }
    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  return (
    <StyledEdit>
      {showModal && (
        <div className="modalContainer">
          <div className="modalBackground" role="presentation" onClick={() => setShowModal(false)} />
          <div className="modalWindow">
            <div className="modalHeader">
              <button type="button" onClick={() => setShowModal(false)}>X</button>
            </div>
            <div className="modalBody">
              <div className="modalText">{`Do you want to delete all records of ${customers.find((i) => i.id === +order.customerId).name} of ${order.date}?`}</div>
              <div className="modalBottom">
                <div className="cancelButton">
                  <CancelButton
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </CancelButton>
                </div>
                <div className="deleteButton">
                  <DeleteButton
                    type="button"
                    onClick={deleteAll}
                  >
                    DELETE
                  </DeleteButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`selectDiv ${showModal ? 'blur' : ''}`}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={fetchRecords}
        >
          {({
            dirty,
            isValid,
            isSubmitting,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="dateInput">
                <InputGroup
                  id="date"
                  type="date"
                  text="Date"
                  name="date"
                />
              </div>
              <div className="customerSelect">
                <SelectGroup
                  id="customerId"
                  name="customerId"
                  text="Customer"
                  options={customers}
                />
              </div>
              <div className="confirmButton">
                <ConfirmButton type="submit" disabled={!dirty || !isValid || isSubmitting}>
                  {isSubmitting ? <Loading /> : 'Fetch'}
                </ConfirmButton>
              </div>
              {/*
              <div className="autoButtonDiv">
                <SpecialButton type="button" onClick={autoMakePlanButtonClicked} disabled={autoMaking}>
                  {autoMaking ? <Loading /> : 'AUTO'}
                </SpecialButton>
              </div>
              */}
            </form>
          )}
        </Formik>
      </div>
      {order.customerId !== 0 && (
        <Formik
          initialValues={recordsInitialValues}
          validationSchema={recordsValidationSchema}
          onSubmit={updateRecords}
        >
          {({
            values, isValid, isSubmitting, handleReset,
          }) => (
            <Form className={showModal ? 'blur' : ''}>
              <div className="formHeader">
                <div className="customer">{customers.find((i) => i.id === +order.customerId).name}</div>
                <div className="date">{`${order.date} ${format(new Date(order.date), 'iii')}`}</div>
              </div>
              <div className="formBody">
                <FieldArray name="records">
                  {({ remove, push }) => 
                    productTypes
                    .filter((productType) => productType.active) // Filter active product types
                    .map((productType) => {
                    const availableProducts = products
                      .filter((p) => p.productTypeId === productType.id
                        && !values.records.map((r) => r.product_id).includes(p.id));

                    return (
                      <div className="productTypeDiv" key={productType.name}>
                        <div className="header">
                          <div className="title">{productType.name}</div>
                          {availableProducts.length > 0 && (
                          <select defaultValue="0" onChange={(e) => changeSelect(e, push)}>
                            <option value="0">Select to add</option>
                            {availableProducts.map((product) => (
                              <option
                                key={product.name}
                                value={product.id}
                              >
                                {product.name}
                              </option>
                            ))}
                          </select>
                          )}
                        </div>
                        <div className="body">
                          <div className="rowTitle">
                            <div>Product</div>
                            <div>Sold</div>
                            <div>Delivery</div>
                            <div />
                          </div>
                          {values.records
                            .sort((a, b) => a.product_id - b.product_id)
                            .map((record, index) => {
                              if (record.product_type_id === productType.id) {
                                return (
                                  <div
                                    className={`row ${record.id ? '' : 'new'}${(record.id && +record.quantity_delivery !== +rawRecords.find((item) => item.id === record.id).quantity_delivery) ? 'changed' : ''}`}
                                    key={`${record.date}-${record.product}`}
                                  >
                                    <div className="productName">{record.product}</div>
                                    <div className="sold">{record.quantity_sold}</div>
                                    <div className="quantity"><Field type="number" name={`records.${index}.quantity_delivery`} /></div>
                                    <button
                                      type="button"
                                      className="deleteIcon"
                                      onClick={() => deleteButtonClicked(remove, record, index)}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                );
                              }
                              return null;
                            })}
                        </div>
                      </div>
                    );
                  })}
                </FieldArray>
              </div>
              <div className="buttonRow">
                <div className="deleteButton">
                  <DeleteButton
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    DELETE ALL
                  </DeleteButton>
                </div>
                <div className="resetButton">
                  <CancelButton
                    type="button"
                    disabled={sameArray(values.records, rawRecords)}
                    onClick={handleReset}
                  >
                    Reset
                  </CancelButton>
                </div>
                <div className="confirmButton">
                  <UpdateButton
                    type="submit"
                    disabled={sameArray(values.records, rawRecords) || !isValid || isSubmitting}
                  >
                    {isSubmitting ? <Loading /> : 'Update'}
                  </UpdateButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </StyledEdit>
  );
};

export default Edit;
