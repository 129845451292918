import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../reducers/modal';
import { StyledUsers } from './styles';

const Users = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state);

  const switchModal = (type, category, target) => {
    dispatch(openModal({ type, category, target }));
  };

  return (
    <StyledUsers>
      <div className="usersTable">
        <div className="tableTitle">
          <div>Users</div>
          <button type="button" onClick={() => switchModal('add', 'user')}>Add</button>
        </div>
        <div className="tableHeader">
          <div>Name</div>
          <div>Email</div>
          <div>Role</div>
        </div>
        <div className="tableBody">
          {users.length > 0 && users.map((user) => (
            <div role="presentation" className="row" key={user.name} onClick={() => switchModal('edit', 'user', user)}>
              <div>{user.name}</div>
              <div>{user.username}</div>
              <div>{user.role}</div>
            </div>
          ))}
        </div>
      </div>
    </StyledUsers>
  );
};

export default Users;
