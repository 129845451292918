import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  type: undefined, // 'add' || 'edit'
  category: undefined, // 'user', 'group', 'customer', 'type', 'product'
  target: undefined, // object
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action) {
      return {
        isOpen: true,
        ...action.payload,
      };
    },
    closeModal() {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
