import { createSlice } from '@reduxjs/toolkit';
import { getAllProductTypes } from '../services/productType';

const productTypeSlice = createSlice({
  name: 'productTypes',
  initialState: [],
  reducers: {
    setProductTypes(state, action) {
      return action.payload;
    },
    appendProductType(state, action) {
      state.push(action.payload);
    },
    modifyProductType(state, action) {
      return state
        .map((item) => (
          item.id === action.payload.id
            ? { ...item, name: action.payload.name }
            : item
        ));
    },
    removeProductType(state, action) {
      return state
        .filter((item) => item.id !== action.payload.id);
    },
  },
});

export const {
  setProductTypes, appendProductType, modifyProductType, removeProductType,
} = productTypeSlice.actions;

export const initializeProductTypes = () => async (dispatch) => {
  const productTypes = await getAllProductTypes();
  dispatch(setProductTypes(productTypes));
};

export default productTypeSlice.reducer;
