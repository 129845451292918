import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/* Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';
/* End of icons */
import { openModal } from '../reducers/modal';
import { StyledProducts, StyledSelectGroup } from './styles';

const Products = () => {
  const pageSize = 15;
  const dispatch = useDispatch();
  const { productTypes, products } = useSelector((state) => state);
  const [productsToShow, setProductsToShow] = useState([]);
  const [productsPageNumber, setProductsPageNumber] = useState(0);
  const [typesPageNumber, setTypesPageNumber] = useState(0);
  const productsMaxPageNumber = Math.ceil(productsToShow.length / pageSize);
  const typesMaxPageNumber = Math.ceil(productTypes.length / pageSize);

  useEffect(() => {
    setProductsToShow(products);
  }, [products]);

  const productTypesFilter = ({ target }) => {
    let productsAfterFilter = products;
    if (target.value !== '0') {
      productsAfterFilter = products.filter((item) => item.productTypeId === +target.value);
    }

    setProductsToShow(productsAfterFilter);
    setProductsPageNumber(0);
  };

  const switchModal = (type, category, target) => {
    dispatch(openModal({ type, category, target }));
  };

  return (
    <StyledProducts>
      <div className="typeTable">
        <div className="tableTitle">
          <div>Types</div>
          <AddCircleIcon type="button" onClick={() => switchModal('add', 'type')} />
        </div>
        <div className="tableHeader">
          <div>Name</div>
        </div>
        <div className="tableBody">
          {productTypes
            .slice(typesPageNumber * pageSize, (typesPageNumber + 1) * pageSize)
            .map((type) => (
              <div
                key={type.name}
                className="row editable"
                role="presentation"
                onClick={() => switchModal('edit', 'type', type)}
              >
                <div>{type.name}</div>
              </div>
            ))}
        </div>
        <div className="tableFooter">
          <div className="pageGroup">
            <button
              type="button"
              onClick={() => setTypesPageNumber(typesPageNumber - 1)}
              disabled={typesPageNumber < 1}
            >
              {'<'}
            </button>
            <div>{`${Math.min(typesPageNumber + 1, typesMaxPageNumber)} / ${typesMaxPageNumber}`}</div>
            <button
              type="button"
              onClick={() => setTypesPageNumber(typesPageNumber + 1)}
              disabled={typesPageNumber + 1 >= typesMaxPageNumber}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
      <div className="productTable">
        <div className="tableTitle">
          <div>Products</div>
          <AddCircleIcon type="button" onClick={() => switchModal('add', 'product')} />
        </div>
        <div className="tableHeader">
          <div>Name</div>
          <div>EAN</div>
          <div>€ K</div>
          <div>€ S</div>
          <div><InfoIcon /></div>
          <div>Health</div>
        </div>
        <div className="tableBody">
          {productsToShow
            .slice(productsPageNumber * pageSize, (productsPageNumber + 1) * pageSize)
            .map((product) => (
              <div
                key={product.name}
                className="row editable"
                role="presentation"
                onClick={() => switchModal('edit', 'product', product)}
              >
                <div>{product.name}</div>
                <div>{product.ean}</div>
                <div>{product.unitPriceByOrder}</div>
                <div>{product.unitPriceByDeliver}</div>
                <div><CircleIcon color={product.active ? 'success' : 'error'} /></div>
                <div>{product.avg_performance || 'N/A'}</div>
              </div>
            ))}
        </div>
        <div className="tableFooter">
          <div>
            <StyledSelectGroup>
              <label htmlFor="productTypeSelect">{`Product type (${productsToShow.length})`}</label>
              <select id="productTypeSelect" onChange={productTypesFilter}>
                <option value="0">All</option>
                {productTypes.map((item) => (
                  <option value={item.id} key={`option-${item.name}`}>{item.name}</option>
                ))}
              </select>
              <div className="bottomBorder" />
            </StyledSelectGroup>
          </div>
          <div className="pageGroup">
            <button
              type="button"
              onClick={() => setProductsPageNumber(productsPageNumber - 1)}
              disabled={productsPageNumber < 1}
            >
              {'<'}
            </button>
            <div>{`${Math.min(productsPageNumber + 1, productsMaxPageNumber)} / ${productsMaxPageNumber}`}</div>
            <button
              type="button"
              onClick={() => setProductsPageNumber(productsPageNumber + 1)}
              disabled={productsPageNumber + 1 >= productsMaxPageNumber}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </StyledProducts>
  );
};

export default Products;
