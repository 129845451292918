import axios from 'axios';
import socket from './socket';
import config from '../utils/config';

const URL = `${BACKEND_URL}/producttypes`;

const addProductType = async (newProductType) => {
  try {
    const response = await axios.post(URL, newProductType, config);
    socket.emit('add_type', response.data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteProductType = async (target) => {
  try {
    const response = await axios.delete(`${URL}/${target.id}`, config);
    socket.emit('delete_type', target);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllProductTypes = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const updateProductType = async (productType) => {
  try {
    const response = await axios.put(`${URL}`, productType, config);
    socket.emit('update_type', productType);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  addProductType,
  deleteProductType,
  getAllProductTypes,
  updateProductType,
};
