import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { StyledToggleGroup } from './styles';

const ToggleGroup = ({
  id, type, name, text,
}) => (
  <StyledToggleGroup>
    <div>{text}</div>
    <label className="switch">
      <Field id={id} type={type} name={name} />
      <span className="slider" />
    </label>
  </StyledToggleGroup>
);

ToggleGroup.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ToggleGroup;
