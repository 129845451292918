import styled from 'styled-components';

const StyledInputGroup = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  & > label {
    position: absolute;
    bottom: 4px;
    font-size: 18px;
    color: gray;
    transition: all .2s;
  };

  & > input {
    position: absolute;
    bottom: 4px;
    width: 100%;
    padding: 0px;
    border: none;
    outline: none; /* Off the border when input is in focus mode */

    &:focus, &:not([value=""]), &[type=date], &[type=time], &:-webkit-autofill {
      bottom: 4px;
      transform: translateY(0);

      & + label {
        bottom: 30px;
        font-size: 14px;
        transition: all .2s;
      };

      & ~ div {
        height: 2px;
        border-bottom: 2px solid #007FFF;
      }
    };
  };

  & > div {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid lightgray;
  };
`;

export default StyledInputGroup;
