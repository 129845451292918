import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import CryptoJS from 'crypto-js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { setNotification, resetNotification } from '../reducers/notification';
import { uploadSales, uploadOrders } from '../services/records';
import { StyledUpload, Loading } from './styles';


const Upload = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [done, setDone] = useState(false);

  const onFileChange = (event) => {
    setParsedData([]);
    if (event.target.files[0]) {
      Papa.parse(event.target.files[0], {
        delimiter: ';',
        header: true,
        skipEmptyLines: 'greedy',
        transformHeader: (header, index) => ['date', 'customerName', 'productName', 'ean', 'invoiceAmount', 'quantitySold'][index],
        transform: (value, header) => (header === 'invoiceAmount' ? +value.replace(',', '.') : value),
        complete: ({ data }) => {
          setParsedData(data.filter((i) => i.invoiceAmount));
        },
      });
    }
  };

  const onFileUpload = async () => {
    setLoading(true);
    await uploadSales(parsedData);
    dispatch(setNotification({ type: 'success', content: 'Done!' }));
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      setDone(false);
      dispatch(resetNotification());
    }, 3000);
  };

  return (
    <StyledUpload>
      <div className="uploadDiv">
        <input type="file" accept=".csv" onChange={onFileChange} />
        <button type="button" onClick={onFileUpload} disabled={loading || !parsedData.length > 0}>{loading ? <Loading width={16} /> : 'Upload'}</button>
      </div>
      <div className="table">
        <div className="tableTitle">Example:</div>
        <div className="tableHeader">
          <div>Date</div>
          <div>Customer</div>
          <div>Product</div>
          <div>EAN</div>
          <div>Invoice</div>
          <div>Sold</div>
        </div>
        <div className="tableRow">
          <div>2022-06-27</div>
          <div>CYBER MARKET</div>
          <div>Red bull</div>
          <div>001100455300034</div>
          <div>100,12</div>
          <div>10</div>
        </div>
      </div>
      {done && (
      <div className="resultDiv">
        <CheckCircleOutlineIcon />
      </div>
      )}
    </StyledUpload>
  );
};

const UploadMike = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [done, setDone] = useState(false);

  const onFileChange = (event) => {
    setParsedData([]);
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const workbook = XLSX.read(buffer, { type: 'buffer' });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        // Read only the range A:H from the sheet
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        range.s.r = 2; // Start from the second row (skip headers)
        range.e.c = 7; // 7 is the index for column H
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          range: XLSX.utils.encode_range(range),
          blankrows: false, // skip blank rows
        });
        
        const transformedData = data.filter((row) => row.length >= 5).flatMap((row) => {
          // Split the product field into individual items
          const products = row[6].split(';').filter((p) => p.trim());

          // Create a row object with common fields and products
          const rowObject = {
            id: row[0],
            timeStamp: row[1],
            customerName: row[2],
            date: row[3],
            wechat: row[4],
            email: row[5],
            productSummary: row[6],
            subtotal: row[7],
            randomCode: row[8],
          };

          // Transform and unpivot product data, generating a hash for each row
          const unpivotedData = products.map((product) => {
            // Further split each product item into its components
            const [productDetails, quantity] = product.split('*');
            const [productName, productId] = productDetails.split('|').map((p) => p.trim());

            // Generate a hash for this specific row
            const rowString = JSON.stringify({
              ...rowObject,
              productName: productName,
              ean: parseInt(productId),
              quantitySold: parseInt(quantity),
            });
            const hash = CryptoJS.SHA256(rowString).toString();

            return {
              ...rowObject,
              productName: productName,
              ean: parseInt(productId),
              quantitySold: parseInt(quantity),
              hash: hash, // Include the hash for this row
            };
          });

          return unpivotedData;
        });

        setParsedData(transformedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const onFileUpload = async () => {
    setLoading(true);
    await uploadOrders(parsedData);
    dispatch(setNotification({ type: 'success', content: 'Done!' }));
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      setDone(false);
      dispatch(resetNotification());
    }, 3000);
  };

  return (
    <StyledUpload>
      <div className="uploadDiv">
        <input type="file" accept=".xlsx" onChange={onFileChange} />
        <button type="button" onClick={onFileUpload} disabled={loading || !parsedData.length > 0}>
          {loading ? <Loading width={16} /> : 'Upload'}
        </button>
      </div>

      {/* Data Table */}
      <div className="dataTable">
        {parsedData.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Store Name</th>
                <th>Delivery Date</th>
                <th>Product Name</th>
                <th>Product ID</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr key={index}>
                  <td>{item.customerName}</td>
                  <td>{item.date}</td>
                  <td>{item.productName}</td>
                  <td>{item.ean}</td>
                  <td>{item.quantitySold}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {done && (
        <div className="resultDiv">
          <CheckCircleOutlineIcon />
        </div>
      )}
    </StyledUpload>
  );
};


export { Upload, UploadMike };
