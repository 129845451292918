import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../reducers';

const LogOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout())
      .then(() => {
        navigate('/', { replace: true });
      });
  }, []);

  return <div>Logging out...</div>;
};

export default LogOut;
