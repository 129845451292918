import axios from 'axios';
import config from '../utils/config';

const URL = `${BACKEND_URL}/records`;

const getAllRecords = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const addRecord = async (newRecord) => {
  try {
    const response = await axios.post(URL, newRecord, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateRecord = async (id, newRecord) => {
  try {
    const response = await axios.put(`${URL}/${id}`, newRecord, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Handle uplodad sales
const uploadSales = async (data) => {
  await axios.post(`${URL}/upload`, data, config);
};

// Handle uplodad order data from MikeCRM
const uploadOrders = async (data) => {
  await axios.post(`${URL}/uploadorder`, data, config);
};


const getAllReports = async (customerId, startDate, endDate, productTypeId) => {
  const response = await axios.get(`${URL}/reports/${customerId}/${productTypeId}/?startDate=${startDate}&endDate=${endDate}`, config);
  return response.data;
};

const getDashboardData = async () => {
  const response = await axios.get(`${URL}/dashboard`, config);
  return response.data;
};

const getRecordsToEdit = async (date, customerId) => {
  const response = await axios.get(`${URL}/edit?date=${date}&customerId=${customerId}`, config);
  return response.data;
};

const deleteRecordsOfDay = async (date, customerId) => {
  const response = await axios.delete(`${URL}/edit?date=${date}&customerId=${customerId}`, config);
  return response.data;
};

const updateRecordsOfDay = async (customerId, data) => {
  const response = await axios.post(`${URL}/edit?customerId=${customerId}`, data, config);
  return response.data;
};

const autoMakePlan = async () => {
  const response = await axios.post(`${URL}/autoplan`, config);
  return response.data;
};

export {
  getAllRecords,
  addRecord,
  updateRecord,
  uploadSales,
  uploadOrders,
  getAllReports,
  getRecordsToEdit,
  deleteRecordsOfDay,
  getDashboardData,
  updateRecordsOfDay,
  autoMakePlan,
};
