import { createSlice } from '@reduxjs/toolkit';
import { getAllUsers } from '../services/users';

const userSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUsers(state, action) {
      return action.payload;
    },
    appendUser(state, action) {
      state.push(action.payload);
    },
    modifyUser(state, action) {
      return state
        .map((user) => (
          user.id === action.payload.id
            ? { ...user, ...action.payload }
            : user
        ));
    },
    removeUser(state, action) {
      return state
        .filter((user) => user.id !== action.payload.id);
    },
  },
});

export const {
  setUsers, appendUser, modifyUser, removeUser,
} = userSlice.actions;

export const initializeUsers = () => async (dispatch) => {
  const users = await getAllUsers();
  dispatch(setUsers(users));
};

export default userSlice.reducer;
