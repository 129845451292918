import axios from 'axios';
import config from '../utils/config';

const URL = `${BACKEND_URL}/invoicetypes`;

const getAllInvoiceTypes = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const addInvoiceType = async (name) => {
  const response = await axios.post(URL, { name }, config);
  return response.data;
};

const updateInvoiceType = async (id, name) => {
  await axios.put(`${URL}/${id}`, { name }, config);
};

export {
  getAllInvoiceTypes,
  addInvoiceType,
  updateInvoiceType,
};
