import React from 'react';
import {Upload, UploadMike} from './Upload';
import PDF from './PDF';
import DownloadXLSX from './DownloadXLSX';
import { StyledFiles } from './styles';

const Files = () => (
  <StyledFiles>
    <div className="uploadDiv">
      <div className="dailyReport">
{/*         <div className="divHeader">Upload report</div>
        <Upload /> */}
        <div className="divHeader">Upload MikeCRM report</div>
        <UploadMike />
      </div>
    </div>
    <div className="downloadDiv">
      <div className="pdfDiv">
        <div className="divHeader">PDF</div>
        <PDF />
      </div>
      <div className="reportDiv">
        <div className="divHeader">Customers list</div>
        <DownloadXLSX address="customerlist" />
      </div>
      <div className="reportDiv">
        <div className="divHeader">K-style Customer report</div>
        <DownloadXLSX address="kReport" />
      </div>
      <div className="reportDiv">
        <div className="divHeader">Customer and Product Type report</div>
        <DownloadXLSX address="allReport" />
      </div>
    </div>
  </StyledFiles>
);

export default Files;
