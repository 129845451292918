/* eslint-disable react/prop-types */
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputGroup from './InputGroup';
import { StyledDownloadXLSX, ConfirmButton } from './styles';

const DownloadXLSX = ({ address }) => {
  const initialValues = {
    startDate: '',
    endDate: '',
  };

  const validationSchema = Yup.object({
    startDate: Yup
      .date()
      .required(),
    endDate: Yup
      .date()
      .min(Yup.ref('startDate'))
      .required(),
  });

  const fetchButtonClicked = async (values, { setSubmitting, resetForm }) => {
    resetForm();
    setSubmitting(false);
  };

  const resetButtonClicked = (resetForm) => {
    resetForm();
  };

  return (
    <StyledDownloadXLSX>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={fetchButtonClicked}
      >
        {({
          values,
          handleSubmit,
          dirty,
          isValid,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="inputDiv">
              <InputGroup id="startDate" key="startDate" name="startDate" type="date" text="Start Date" />
              <InputGroup id="endDate" key="endDate" name="endDate" type="date" min={values.startDate} text="End Date" />
            </div>
            <div className="buttonsDiv">
              <div className="confirmButton">
                <ConfirmButton disabled={!dirty || !isValid || isSubmitting}>
                  <a className={(!dirty || !isValid || isSubmitting) ? 'disabled' : ''} href={`${BACKEND_URL}/files/${address}?startDate=${values.startDate}&endDate=${values.endDate}`}>Download</a>
                </ConfirmButton>
              </div>
              <div>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => resetButtonClicked(resetForm)}
                  disabled={!dirty || isSubmitting}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </StyledDownloadXLSX>
  );
};

export default DownloadXLSX;
