import styled from 'styled-components';
import { FadeIn } from './animations';

const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  animation: ${FadeIn} 1s;

  .chartTitle {
    font-size: 20px;
  };

  .chartDiv {
    border-radius: 8px;
    box-shadow: 0px 0px 8px 8px lightgray;
    padding: 12px;
    box-sizing: border-box;
  };

  .tooltipDiv {
    min-width: 240px;
    width: fit-content;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;

    .tooltipTitle {
      font-size: 18px;
    };

    .tooltipBody {
      width: 100%;
      
      .tooltipRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      };
    };
  };

  .upperDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .areaChartDiv {
      width: 440px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .chart {
        width: 416px;
        height: 246px;
      };
    };
  };

  .lowerDiv {
    width: 100%;
    height: 672px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .lowerLeft {
      width: 940px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    };

    .lowerRight {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      .lowerRightTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 192px;
      };

      .lowerRightBottom {
        width: 440px;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      };
    };
  };
`;

export default StyledDashboard;
