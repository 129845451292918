import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { StyledSelectGroup } from './styles';

const SelectGroup = ({
  id, name, text, options,
}) => (
  <StyledSelectGroup>
    <label htmlFor={name}>{text}</label>
    <Field name={name} id={id} as="select">
      <option value="0" disabled>Select</option>
      {options.map((option) => (
        <option key={option.name} value={option.id}>{option.name}</option>
      ))}
    </Field>
    <div className="bottomBorder" />
  </StyledSelectGroup>
);

SelectGroup.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SelectGroup;
