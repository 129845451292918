import styled from 'styled-components';

const StyledProfile = styled.div`
  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    & > div {
      width: 240px;
      height: 56px;
    };
  };
`;

export default StyledProfile;
