import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > .modalBackground {
    position: absolute;
    background: rgba(43, 43, 43, 0.8);
    width: 100%;
    height: 100%;
    z-index: 1;
  };

  & > .modalWindow {
    background: #FDFDFD;
    box-shadow: 0px -2px 18px rgba(128, 128, 128, 0.1);
    border-radius: 8px;
    width: 720px;
    height: auto;
    z-index: 2;
    animation: ${appear} .5s;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > .modalHeaderContainer {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;

      > button {
        width: 32px;
        height: 32px;
        background: #2B2B2B;
        color: #FDFDFD;
        border: none;
        border-radius: 0px 8px;

        &:hover {
          cursor: pointer;
        };
      };
    };

    & > form {
      width: 100%;

      & > .formBody {
        box-sizing: border-box;
        padding: 32px;
      
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 48px;
        justify-content: space-between;

        & > div {
          width: 240px;
          height: 56px;
        };
      };

      & > .formFooter {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        & > .buttonDiv {
          width: 240px;
          height: 48px;
        };

        & .deleteButton {
          transition: all .5s;
          &:hover {
            cursor: pointer;
            font-size: 2rem;
            color: red;
            transition: all .5s;
          };
        };
      };
    };
  };
`;

export default Modal;
