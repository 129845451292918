import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputGroup from './InputGroup';
import { StyledPDF, ConfirmButton } from './styles';

const PDF = () => {
  const dayProductionInitialValues = { date: '' };

  const dayProductionValidationSchema = Yup.object({
    date: Yup
      .date()
      .required(),
  });

  const getDayProductionButtonClicked = ({ resetForm }) => {
    resetForm();
  };

  return (
    <StyledPDF>
      <Formik
        initialValues={dayProductionInitialValues}
        validationSchema={dayProductionValidationSchema}
        onSubmit={getDayProductionButtonClicked}
      >
        {({
          handleSubmit,
          values,
          dirty,
          isValid,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <h3>Day Production</h3>
            <div className="inputDiv"><InputGroup id="date" key="date" name="date" type="date" text="Date" /></div>
            <div className="buttonsDiv">
              <div className="confirmButton">
                <ConfirmButton type="submit" disabled={!dirty || !isValid || isSubmitting}>
                  <a
                    className={(!dirty || !isValid || isSubmitting) ? 'disabled' : ''}
                    href={`${BACKEND_URL}/files/dayproduction?date=${values.date}`}
                  >
                    Download
                  </a>
                </ConfirmButton>
              </div>
              <div>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => resetForm()}
                  disabled={!dirty || isSubmitting}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </StyledPDF>
  );
};

export default PDF;
