import styled from 'styled-components';

const StyledApp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .navi-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #0D47A1;
    border-radius: 4px;

    .active, a:hover {
      background-color: #1976D2;
      border-radius: 4px;
    }

    a {
      font-size: 20px;
      text-decoration: none;
      padding: 8px;
      color: white;
    }
  }

  .routes {
    width: 100%;
  };
`;

export default StyledApp;
