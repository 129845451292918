import { createSlice } from '@reduxjs/toolkit';
import { getAllGroups } from '../services/groups';

const groupSlice = createSlice({
  name: 'groups',
  initialState: [],
  reducers: {
    setGroups(state, action) {
      return action.payload;
    },
    appendGroup(state, action) {
      state.push(action.payload);
    },
    modifyGroup(state, action) {
      return state
        .map((item) => (
          item.id === action.payload.id
            ? { ...item, name: action.payload.name }
            : item
        ));
    },
    removeGroup(state, action) {
      return state
        .filter((group) => group.id !== action.payload.id);
    },
  },
});

export const {
  setGroups, appendGroup, modifyGroup, removeGroup,
} = groupSlice.actions;

export const initializeGroups = () => async (dispatch) => {
  const groups = await getAllGroups();
  dispatch(setGroups(groups));
};

export default groupSlice.reducer;
