import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import store from './store';
import App from './App';

const GlobalStyle = createGlobalStyle`
  html {
    width: 1456px;
    margin: auto;
    font-family: 'Roboto Mono', monospace;

    button, input, select, option {
      font-family: 'Roboto Mono', monospace;
    };
  };
`;

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
    <GlobalStyle />
  </Provider>,
);
