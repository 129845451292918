import axios from 'axios';
import socket from './socket';
import config from '../utils/config';

const URL = `${BACKEND_URL}/products`;

const addProduct = async (product) => {
  try {
    const response = await axios.post(URL, product, config);
    socket.emit('add_product', response.data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteProduct = async (target) => {
  try {
    const response = await axios.delete(`${URL}/${target.id}`, config);
    socket.emit('delete_product', target);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllProducts = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const updateProduct = async (product) => {
  try {
    const response = await axios.put(`${URL}`, product, config);
    socket.emit('update_product', product);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  addProduct,
  deleteProduct,
  getAllProducts,
  updateProduct,
};
