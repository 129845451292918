import styled from 'styled-components';
import { FadeIn } from './animations';

const StyledUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .uploadDiv {
    width: 100%;

    button {
      border: none;
      border-radius: 8px;
      width: 120px;
      height: 32px;
      font-size: 14px;
      background-color: lightgray;

      &:not([disabled]) {
        cursor: pointer;
        background-color: #1976D2;
        color: #FFFFFF;
      };
    };
  };

  .table {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tableHeader, .tableRow {
      display: grid;
      grid-template-columns: 88px 104px 136px 128px 80px 64px;
      gap: 70px;
      border-bottom: 1px solid lightgray;
      padding: 0px 2px;
      font-size: 18px;

      div:nth-child(5), div:last-child {
        text-align: right;
      };
    };

    .tableRow {
      font-size: 14px;
      border-bottom: none;
    };
  };

  .resultDiv {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: ${FadeIn} 1s;
    
    svg {
      width: 100px;
      height: 100px;
      color: lightgreen;
    };
  };
`;

export default StyledUpload;
