import styled from 'styled-components';

const StyledPDF = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  & > form {
    display: flex;
    flex-direction: column;

    & > .inputDiv {
      height: 48px;
    };

    & > .buttonsDiv {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 4px;

      & > .confirmButton {
        width: 120px;
        height: 32px;

        & a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #FFFFFF;
        };

        & a.disabled {
          color: rgba(16, 16, 16, 0.3);
          pointer-events: none;
        };
      };
    };

    & .resetButton {
      border: none;
      width: 120px;
      height: 32px;
      border-radius: 8px;
      padding: 0px;
      font-size: 18px;

      &[disabled] {
        background-color: lightgray;
      };

      &:not([disabled]) {
        background-color: #16DB65;;
        color: #FFFFFF;

        &:hover {
          cursor: pointer;
        };
      };
    };
  };
`;

export default StyledPDF;
