import { createSlice } from '@reduxjs/toolkit';
import { getDashboardData } from '../services/records';

const initialState = {
  complete: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard(state, action) {
      return {
        complete: true,
        ...action.payload,
      };
    },
  },
});

export const { setDashboard } = dashboardSlice.actions;

export const initializeDashboard = () => async (dispatch) => {
  const data = await getDashboardData();
  dispatch(setDashboard(data));
};

export default dashboardSlice.reducer;
