import styled from 'styled-components';

const StyledEdit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  .blur {
    filter: blur(4px);
    -webkit-filter: blur(4px);
  };

  .modalContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    
    .modalBackground {
      width: 100%;
      height: 100%;
      background: rgb(128,128,128, 0.5);
    };

    .modalWindow {
      width: 400px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .modalHeader {
        width: 100%;
        height: 24px;
        display: flex;
        flex-direction: row-reverse;

        button {
          border: none;
          border-top-right-radius: 8px;
          font-size: 1rem;
          font-weight: bolder;
          
          &:hover {
            cursor: pointer;
          };
        };
      };

      .modalBody {
        height: 100%;
        text-align: center;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .modalText {
          font-weight: bold;
        };

        .modalBottom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          div {
            width: 160px;
            height: 48px;
          };
        };
      };
    };
  };

  .selectDiv {
    > form {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5%;

      > div {
        width: 160px;
        height: 48px;
      };

      .autoButtonDiv {
        position: relative;
        /* Arrow */
        > button:hover::before {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
          border-color: transparent transparent black transparent;
          border-width: 10px;
          border-style: solid;
        };

        > button:hover::after {
          content: 'Auto make next week plan of Sushi, Poke and Hotmeal for all S-style customers';
          position: absolute;
          bottom: -85px;
          left: 0px;
          background-color: black;
          color: white;
          font-size: 0.8rem;
          border-radius: 4px;
          padding: 4px;
        };
      };
    };
  };

  > form {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;

    .formHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2rem;
      font-weight: bolder;

      .date {
        font-size: 1.5rem;
        font-weight: normal;
      };
    };

    .formBody {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 20px;

      .productTypeDiv {
        width: 440px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 4px;

        .header {
          width: 100%;
          display: grid;
          align-items: center;
          grid-template-columns: repeat(2, 50%);

          .title {
            font-size: 1.5rem;
          };

          select {
            width: 100%;
            height: 30px;
            border-radius: 4px;
            padding-left: 4px;
          };
        };

        .body {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 4px;
          font-size: 0.8rem;
          
          .row {
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: 60% 10% 25% 5%;

            .sold {
              text-align: center;
            };

            .quantity {
              display: flex;
              flex-direction: column;
              align-items: center;

              input {
                width: 24px;
                text-align: center;
                border: 1px solid lightgrey;
                border-radius: 4px;

                /* Hide the up and down buttons */
                &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  margin: 0;
                };
              };
            };

            .deleteIcon {
              display: flex;
              flex-direction: column;
              margin: 0px;
              padding: 0px;
              border: transparent;
              background: none;
              align-items: center;
              justify-content: center;
              transition: all 0.2s;

              &:hover {
                cursor: pointer;
                color: red;
                transform: scale(1.1);
                transition: all 0.2s;
              };

              &:active {
                transform: scale(0.8);
              };
            };
          };

          .new {
            background-color: rgba(92, 255, 160, 0.3);
          };

          .changed {
            background-color: rgba(0, 127, 255, 0.3);
          };

          .rowTitle {
            width: 100%;
            display: grid;
            grid-template-columns: 60% 10% 25% 5%;
            font-weight: bold;
            font-size: 1rem;
            border-top: 2px solid lightgrey;
            border-bottom: 2px solid lightgrey;
            text-align: center;

            & > div:first-child {
              text-align: left;
            };
          };
        };
      };
    };

    .buttonRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 10%;
      box-sizing: border-box;

      .deleteButton, .resetButton, .confirmButton {
        width: 160px;
        height: 48px;
      };
    };
  };
`;

export default StyledEdit;
