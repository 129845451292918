import axios from 'axios';
import config from '../utils/config';

const URL = `${BACKEND_URL}/auth`;

const checkAuth = async () => {
  const response = await axios.get(`${URL}/check`, config);
  return response.data;
};

const processLogin = async (loginData) => {
  try {
    const response = await axios.post(`${URL}/login`, loginData, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const processLogout = async () => {
  await axios.post(`${URL}/logout`, {}, config);
};

const changePassword = async (data) => {
  try {
    const response = await axios.put(`${URL}/changePassword`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  checkAuth,
  processLogin,
  processLogout,
  config,
  changePassword,
};
