import { keyframes } from 'styled-components';

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

const SlideIn = keyframes`
  from {
    opacity: 0;
    margin-left: -100%;
  }
`;

const Rotate = keyframes`
  0% {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const Gradient = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export {
  FadeIn,
  SlideIn,
  Rotate,
  Gradient,
};
