import styled from 'styled-components';

const StyledSelectGroup = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;

  & > label {
    position: absolute;
    bottom: 30px;
    font-size: 14px;
    color: gray;
  };

  & > select {
    position: absolute;
    bottom: 4px;
    width: 100%;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 0px;

    & > option: {
      padding-left: 0px !important;
    };

    &:not([value="0"]) + .bottomBorder{
      border-bottom: 2px solid #16DB65;
    };
  };

  & > .bottomBorder {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid lightgray;
  };
`;

export default StyledSelectGroup;
