import styled from 'styled-components';

const StyledLogin = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    & > div {
      width: 240px;
      height: 56px;
    };
  };
`;

export default StyledLogin;
