import { createSlice } from '@reduxjs/toolkit';
import { getAllRecords } from '../services/records';

const recordSlice = createSlice({
  name: 'records',
  initialState: [],
  reducers: {
    setRecords(state, action) {
      return action.payload;
    },
    appendRecord(state, action) {
      state.push(action.payload);
    },
    modifyRecord(state, action) {
      return state
        .map((item) => (
          item.id === action.payload.id
            ? { ...action.payload }
            : item
        ));
    },
  },
});

export const { setRecords, appendRecord, modifyRecord } = recordSlice.actions;

export const initializeRecords = () => async (dispatch) => {
  const records = await getAllRecords();
  dispatch(setRecords(records));
};

export default recordSlice.reducer;
