import { createSlice } from '@reduxjs/toolkit';
import {
  getAllInvoiceTypes,
  addInvoiceType,
} from '../services/invoiceType';

const invoiceTypeSlice = createSlice({
  name: 'invoiceTypes',
  initialState: [],
  reducers: {
    setInvoiceTypes(state, action) {
      return action.payload;
    },
    appendInvoiceType(state, action) {
      state.push(action.payload);
    },
    updateInvoiceType(state, action) {
      return state
        .map((item) => (
          item.id === action.payload.id ? { ...item, name: action.payload.name } : item
        ));
    },
  },
});

export const { setInvoiceTypes, appendInvoiceType, updateInvoiceType } = invoiceTypeSlice.actions;

export const initializeInvoiceTypes = () => async (dispatch) => {
  const invoiceTypes = await getAllInvoiceTypes();
  dispatch(setInvoiceTypes(invoiceTypes));
};

export const createInvoiceType = (name) => async (dispatch) => {
  const newInvoiceType = await addInvoiceType(name);
  dispatch(appendInvoiceType(newInvoiceType));
};

export default invoiceTypeSlice.reducer;
