import axios from 'axios';
import config from '../utils/config';

const URL = `${BACKEND_URL}/users`;

const addUser = async (newUser) => {
  try {
    const response = await axios.post(URL, newUser, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${URL}/${id}`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllUsers = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const updateUser = async (user) => {
  try {
    const response = await axios.put(`${URL}`, user, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  addUser,
  deleteUser,
  getAllUsers,
  updateUser,
};
