import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  padding: 0px;
  border-radius: 8px;
  font-size: 18px;
  transition: all .2s;

  &:disabled {
    background-color: lightgray;
  };

  &:not([disabled]) {
    color: #FFFFFF;

    &:hover {
      cursor: pointer;
      transition: all .2s;
      box-shadow: 0px 0px 8px lightgray;
    };

    &:active {
      transform: scale(0.9)
    };
  };

`;

const UpdateButton = styled(Button)`
  &:not([disabled]) {
    background-color: #FDBC11;
    
    &:hover {
      background-color: #FDA811;
    };
  };
`;

const ConfirmButton = styled(Button)`
  &:not([disabled]) {
    background-color: #007FFF;
    
    &:hover {
      background-color: #006BD8;
    };
  };
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid lightgray;
  box-sizing: border-box;
  color: gray;

  &:not([disabled]) {
    color: gray;
  };
`;

const DeleteButton = styled(Button)`
  background-color: rgba(255, 0, 0, 1);
  color: white;

  &:hover {
    background-color: rgba(255, 0, 0, 0.5);
  };
`;

const SpecialButton = styled(Button)`
  background-color: rgba(241, 91, 181, 1);
  color: white;

  &:hover {
    background-color: rgba(241, 91, 181, 0.5);
  };
`;

export {
  UpdateButton,
  ConfirmButton,
  CancelButton,
  DeleteButton,
  SpecialButton,
};
