import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setNotification, resetNotification } from '../reducers/notification';
import { processLogin } from '../services/auth';
import { setAuth } from '../reducers/auth';
import InputGroup from './InputGroup';
import { StyledLogin, ConfirmButton, Loading } from './styles';

const Login = () => {
  const dispatch = useDispatch();
  const initialValues = { email: '', password: '' };

  const validationSchema = Yup.object({
    email: Yup
      .string()
      .email('Invalid Email')
      .required('Email required'),
    password: Yup
      .string()
      .required('Password required'),
  });

  const loginButtonClicked = async (values, { setSubmitting, resetForm }) => {
    const data = {
      username: values.email,
      password: values.password,
    };

    const response = await processLogin(data);
    setSubmitting(false);

    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
      resetForm();
    } else {
      dispatch(setAuth({ status: 'pass', user: response }));
      dispatch(setNotification({ type: 'success', content: 'Welcome back' }));
    }
    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  return (
    <StyledLogin>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={loginButtonClicked}
      >
        {({
          errors, touched, isValid, handleSubmit, isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {touched.email && errors.email
              && <div>{errors.email}</div>}
            <div>
              <InputGroup id="email" type="email" name="email" text="Email" />
            </div>
            <div>
              <InputGroup id="password" type="password" name="password" text="Password" />
            </div>
            <div className="buttonDiv">
              <ConfirmButton type="submit" disabled={isSubmitting || !isValid}>{isSubmitting ? <Loading /> : 'Login'}</ConfirmButton>
            </div>
          </form>
        )}
      </Formik>
    </StyledLogin>
  );
};

export default Login;
