import axios from 'axios';
import socket from './socket';
import config from '../utils/config';

const URL = `${BACKEND_URL}/groups`;

const addGroup = async (newGroup) => {
  try {
    const response = await axios.post(URL, newGroup, config);
    socket.emit('add_group', response.data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteGroup = async (target) => {
  try {
    const response = await axios.delete(`${URL}/${target.id}`, config);
    socket.emit('delete_group', target);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllGroups = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const updateGroup = async (group) => {
  try {
    const response = await axios.put(`${URL}`, group, config);
    socket.emit('update_group', group);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  addGroup,
  deleteGroup,
  getAllGroups,
  updateGroup,
};
