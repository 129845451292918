import styled from 'styled-components';

const StyledFiles = styled.div`
  display: grid;
  grid-template-columns: 960px 440px;
  grid-gap: 40px;

  & > div {
    min-height: 800px;
    max-height: fit-content;
    border: 2px solid lightgray;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    & > div {
      width: 100%;
      & > .divHeader {
        text-align: center;
        font-size: 24px;
      };
    };
  };
`;

export default StyledFiles;
