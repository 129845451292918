import { createSlice } from '@reduxjs/toolkit';
import { getAllReports } from '../services/records';

const initialState = {
  summary: {},
  data: [],
};

const reportReducer = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports(state, action) {
      return action.payload;
    },
    resetReports() {
      return initialState;
    },
  },
});

export const { setReports, resetReports } = reportReducer.actions;

// eslint-disable-next-line max-len
export const initializeReports = (customerId, startDate, endDate, productTypeId) => async (dispatch) => {
  const reports = await getAllReports(customerId, startDate, endDate, productTypeId);
  dispatch(setReports(reports));
};

export default reportReducer.reducer;
