import styled from 'styled-components';

const StyledUsers = styled.div`
  width: 960px;
  margin: auto;
  
  display: flex;
  flex-direction: column;

  & .usersTable {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .tableTitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      & > div {
        font-size: 36px;
        font-weight: bold;
      };
      /* Add button */
      & > button {
        width: 128px;
        height: 41px;
        border: none;
        border-radius: 4px;
        background-color: #1976D2;
        box-shadow: 2px 2px 6px gray;
        font-size: 18px;
        color: #FFFFFF;
        transition: all .2s;

        &:hover {
          cursor: pointer;
          box-shadow: none;
          transition: all .2s;
        };
      }
    };

    & > .tableHeader {
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 384px 384px 96px;
      border-bottom: 4px solid lightgray;
      grid-gap: 48px;
      padding: 10px 0px;
      font-size: 20px;
      font-weight: bold;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 8px;
      };

      & > div:last-child {
        justify-content: right;
      };
    };

    & > .tableBody {
      height: 735px;
      display: flex;
      flex-direction: column;
      align-items: center; 
      border-bottom: 4px solid lightgray;

      & > .row {
        width: 100%;
        display: grid;
        grid-template-columns: 384px 384px 96px;
        border-bottom: 1px solid lightgray;
        grid-gap: 48px;
        align-items: center;
        transition: all .2s;

        &:hover {
          cursor: pointer;
          color: #FFFFFF;
          background-color: #FDBC11;
          transition: all .2s;
        };

        & > div {
          height: 48px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 8px;
          box-sizing: border-box;
        };

        & > div:last-child {
          justify-content: right;
        };
      };
    };
  };
`;

export default StyledUsers;
