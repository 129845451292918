import axios from 'axios';
import socket from './socket';
import config from '../utils/config';

const URL = `${BACKEND_URL}/customers`;

const addCustomer = async (newCustomer) => {
  try {
    const response = await axios.post(URL, newCustomer, config);
    socket.emit('add_customer', response.data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteCustomer = async (target) => {
  try {
    const response = await axios.delete(`${URL}/${target.id}`, config);
    socket.emit('delete_customer', target);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllCustomers = async () => {
  const response = await axios.get(URL, config);
  return response.data;
};

const updateCustomer = async (customer) => {
  try {
    const response = await axios.put(`${URL}`, customer, config);
    socket.emit('update_customer', customer);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  addCustomer,
  deleteCustomer,
  getAllCustomers,
  updateCustomer,
};
