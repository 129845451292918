/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  AreaChart, Area, XAxis, Tooltip,
  ComposedChart, Bar, Line, ScatterChart,
  Scatter, YAxis, ZAxis, ReferenceLine,
} from 'recharts';
import uniqolor from 'uniqolor';

import Loading from './styles/loading';
import { StyledDashboard } from './styles';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="tooltipDiv">
        <div className="tooltipTitle">{payload[0].payload.name}</div>
        <div className="tooltipBody">
          {payload.map((item) => (
            <div key={item.name} className="tooltipRow">
              <div>{`${item.name}:`}</div>
              <div>{`${item.value}${item.unit}`}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

const Dashboard = () => {
  const {
    complete, customerStyles, allAreas, customerAreas,
    allProductTypes, productTypes, sales, products,
    customers,
  } = useSelector((state) => state.dashboard);

  if (!complete) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 1012,
      }}
      >
        <Loading width="64" height="64" color="#1976D2" />
      </div>
    );
  }

  const customerTotalSoldAmount = customers
    .reduce((total, next) => total + next.invoice_amount, 0);
  const customerTotalDeliveredAmount = customers
    .reduce((total, next) => total + next.delivered_amount, 0);
  const customerAvgSoldAmount = Math.round(customerTotalSoldAmount / customers.length);
  const customerAvgPerformance = Math
    .round((customerTotalSoldAmount / customerTotalDeliveredAmount) * 100);

  const productTotalSoldAmount = products
    .reduce((total, next) => total + next.invoice_amount, 0);
  const productTotalDeliveredAmount = products
    .reduce((total, next) => total + next.delivered_amount, 0);
  const productAvgSoldAmount = Math.round(productTotalSoldAmount / products.length);
  const productAvgPerformance = Math
    .round((productTotalSoldAmount / productTotalDeliveredAmount) * 100);
  return (
    <StyledDashboard>
      <div className="upperDiv">
        <div className="areaChartDiv chartDiv">
          <div className="chartTitle">Customer styles</div>
          <div className="chart">
            <AreaChart
              width={416}
              height={246}
              data={customerStyles}
            >
              <defs>
                <linearGradient id="colorK" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F86800" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F86800" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorS" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#018749" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#018749" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Tooltip
                formatter={(value, name) => [`€ ${value}`, name.toUpperCase()]}
              />
              <XAxis dataKey="name" hide />
              <Area type="monotone" stackId={1} dataKey="k" stroke="#F86800" fillOpacity={1} fill="url(#colorK)" />
              <Area type="monotone" stackId={1} dataKey="s" stroke="#018749" fillOpacity={1} fill="url(#colorS)" />
            </AreaChart>
          </div>
        </div>
        <div className="areaChartDiv chartDiv">
          <div className="chartTitle">Customer areas</div>
          <div className="chart">
            <AreaChart
              width={416}
              height={246}
              data={customerAreas}
            >
              <defs>
                {allAreas.map((value, index) => (
                  <linearGradient key={`areacolor${value}`} id={`areacolor${value}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={uniqolor(`areas-${value}${index}`).color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={uniqolor(`areas-${value}${index}`).color} stopOpacity={0} />
                  </linearGradient>
                ))}
              </defs>
              <Tooltip
                formatter={(value, name) => [`€ ${value}`, name.toUpperCase()]}
              />
              <XAxis dataKey="name" hide />
              {allAreas.map((value, index) => (
                <Area key={value} type="monotone" stackId={1} dataKey={value.toLowerCase()} fillOpacity={1} stroke={uniqolor(`areas-${value}${index}`).color} fill={`url(#areacolor${value})`} />
              ))}
            </AreaChart>
          </div>
        </div>
        <div className="areaChartDiv chartDiv">
          <div className="chartTitle">Product types</div>
          <div className="chart">
            <AreaChart
              width={416}
              height={246}
              data={productTypes}
            >
              <defs>
                {allProductTypes.map((value, index) => (
                  <linearGradient key={`typecolor${value}`} id={`typecolor${value}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={uniqolor(`types-${value}${index}`).color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={uniqolor(`types-${value}${index}`).color} stopOpacity={0} />
                  </linearGradient>
                ))}
              </defs>
              <Tooltip
                formatter={(value, name) => [`€ ${value}`, name.toUpperCase()]}
              />
              <XAxis dataKey="name" hide />
              {allProductTypes.map((value, index) => (
                <Area key={value} type="monotone" stackId={1} dataKey={value.toLowerCase()} fillOpacity={1} stroke={uniqolor(`types-${value}${index}`).color} fill={`url(#typecolor${value})`} />
              ))}
            </AreaChart>
          </div>
        </div>
      </div>
      <div className="lowerDiv">
        <div className="lowerLeft chartDiv">
          <div className="chartTitle">Customers</div>
          <div className="chart">
            <ScatterChart
              width={916}
              height={622}
              margin={{
                top: 12, right: 12, bottom: 12, left: 12,
              }}
            >
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                content={<CustomTooltip />}
              />
              <XAxis type="number" dataKey="performance" name="Performance" unit="%" domain={['dataMin', 100]} tickCount={5} />
              <YAxis type="number" dataKey="invoice_amount" name="Amount" unit=" €" domain={[0, 'dataMax']} hide />
              <ZAxis type="number" dataKey="sold_unit" name="Sold" range={[10, 1000]} unit=" kpl" />
              {customers.map((item) => (
                <Scatter key={`products-${item.name}`} name={item.name} data={[item]} fill={uniqolor(item.name).color} shape="square" />
              ))}
              <ReferenceLine
                isFront
                x={customerAvgPerformance}
                stroke="lightgrey"
                strokeDasharray="10 10"
                label={`Avg Performance:${customerAvgPerformance}%`}
              />
              <ReferenceLine
                isFront
                y={customerAvgSoldAmount}
                stroke="lightgrey"
                strokeDasharray="10 10"
                label={`Avg Invoice:${customerAvgSoldAmount} €`}
              />
            </ScatterChart>
          </div>
        </div>
        <div className="lowerRight">
          <div className="lowerRightTop chartDiv">
            <div className="chartTitle">Sales</div>
            <div className="chart">
              <ComposedChart
                data={sales}
                width={416}
                height={142}
              >
                <Tooltip />
                <XAxis dataKey="name" hide />
                <Bar yAxisId="left" dataKey="sold" fill="#003F5C" name="Sold" unit=" €" />
                <Line yAxisId="right" type="monotone" dataKey="original_price_performance" stroke="#BC5090" name="Original Price" unit="%" />
                <Line yAxisId="right" type="monotone" dataKey="performance" stroke="#FFA600" name="Performance" unit="%" />
              </ComposedChart>
            </div>
          </div>
          <div className="lowerRightBottom chartDiv">
            <div className="chartTitle">Products</div>
            <div className="chart">
              <ScatterChart
                width={440}
                height={390}
                margin={{
                  top: 12, right: 12, bottom: 12, left: 12,
                }}
              >
                <Tooltip
                  cursor={{ strokeDasharray: '3 3' }}
                  content={<CustomTooltip />}
                />
                <XAxis type="number" dataKey="performance" name="Performance" unit="%" domain={['dataMin', 100]} tickCount={5} />
                <YAxis type="number" dataKey="invoice_amount" name="Amount" unit=" €" domain={[0, 'dataMax']} hide />
                <ZAxis type="number" dataKey="sold_unit" name="Sold" range={[10, 1000]} unit=" kpl" />
                {products.map((item) => (
                  <Scatter key={`products-${item.name}`} name={item.name} data={[item]} fill={uniqolor(item.name).color} shape="circle" />
                ))}
                <ReferenceLine
                  isFront
                  x={productAvgPerformance}
                  stroke="lightgrey"
                  strokeDasharray="10 10"
                  label={`Avg Performance:${productAvgPerformance}%`}
                />
                <ReferenceLine
                  isFront
                  y={productAvgSoldAmount}
                  stroke="lightgrey"
                  strokeDasharray="10 10"
                  label={`Avg Invoice:${productAvgSoldAmount} €`}
                />
              </ScatterChart>
            </div>
          </div>
        </div>
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
