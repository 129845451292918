/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format, add, parse } from 'date-fns';
import styled from 'styled-components';
import { FadeIn, SlideIn } from './styles/animations';
import { addRecord } from '../services/records';
import { modifyCustomer } from '../reducers/customer';

const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const StyledForm = styled.form`
  position: relative;
  background-color: #F0F0F0;
  border-radius: 8px;
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  animation: ${FadeIn} .5s;

  & > h1 {
    margin: 10px 0px 0px 0px;
  };

  & > button:first-child {
    position: absolute;
    align-self: end;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 18px;
    background-color: transparent;
    color: #000000;
    transition: all .2s;

    &:hover {
      cursor: pointer;
      background-color: #000000;
      color: #FFFFFF;
      font-weight: bold;
      transform: rotate(360deg);
      transition: all .2s;
    };
  };
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: 440px 440px 440px;
  gap: 30px 60px;
`;

const StyledProductType = styled.div`
  border-radius: 16px;
  background-color: #FDFDFD;
  box-shadow: 2px 2px 8px lightgray;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0px 4px 20px 12px;
  align-items: center;
  gap: 20px;
  transition: box-shadow .5s;
  overflow: hidden;

  &:hover {
    box-shadow: 2px 2px 2px darkgray;
    background-color: #FEFEFE;
    transition: all .5s;
  }
`;

const StyledProductRow = styled.div`
  width: 100%;
  min-height: 26px;
  max-height: fit-content;
  display: grid;
  grid-template-columns: 188px 24px 48px 24px 16px;
  grid-column-gap: 8px;
  align-items: center;
  text-align: center;
  animation: ${SlideIn} .5s;

  & > div:first-child, > label {
    text-align: left ;
  };

  & > input {
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 4px;

    &:focus {
      outline: none;
      border: 2px solid #1976D2;
    }
  }

  & > button {
    height: 24px;
    border: none;
    padding: 0px;
    background-color: transparent;
    color: red;
    text-shadow: 2px 2px 8px lightgray;
    transition: all .2s;
  
    &:hover {
      cursor: pointer;
      font-weight: bold;
      text-shadow: 2px 2px 2px darkgray;
      transform: rotate(360deg) scale(1.5);
      transition: all .2s;
    }
  }
`;

const StyledProductHeader = styled(StyledProductRow)`
  font-weight: bold;
  font-size: 18px;
  animation: none;

  &:hover {
    background-color: transparent;
  };
`;

const StyledSelectionRow = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  
  & > select {
    width: 308px;
    border-radius: 4px;
    border: 1px solid lightgray;
  };

  & > button {
    width: 16px;
    border: none;
    padding: 0px;
    background-color: transparent;
    font-size: 18px;
    color: darkgray;
    text-shadow: 2px 2px 8px lightgray;
    transition: all .2s;

    &:enabled {
      color: #1976D2;

      &:hover {
        cursor: pointer;
        text-shadow: 2px 2px 2px darkgray;
        font-weight: bold;
        transform: rotate(360deg) scale(2);
        transition: all .2s;
      };
    };
  };
`;

const StyledFormFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  & > button {
    width: 160px;
    height: 56px;
    border: none;
    background-color: #1976D2;
    border-radius: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    box-shadow: 2px 2px 8px darkgray;
    transition: all .5s;

    &:hover {
      cursor: pointer;
      background-color: #0D47A1;
      box-shadow: 2px 2px 2px darkgray;
      transition: all .5s;
    }
  };
`;

const NewInputGroup = ({
  productName, defaultValue, productId, median, lastWeekSituation,
}) => {
  const [value, setValue] = useState(defaultValue);
  // Somehow this makes the default value updated when changing customer
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <label htmlFor={productId}>{productName}</label>
      <div>{median}</div>
      <div>{lastWeekSituation}</div>
      <input
        id={productId}
        value={value}
        name={productName}
        onChange={({ target }) => setValue(target.value)}
      />
    </>
  );
};

const ProductTypesDiv = ({
  // eslint-disable-next-line no-unused-vars
  productType, data, planWeekday, area,
}) => {
  const originProducts = productType.name === 'Drinks' ? [] : data.filter((i) => !(i.product.delivered[planWeekday] === 0)).map((record) => record.product.name); // Drinks not expand by default
  const allProducts = useSelector((state) => state.products);
  const [existingProducts, setExistingProducts] = useState(productType.name === 'Drinks' ? [] : data.filter((i) => !(i.product.delivered[planWeekday] === 0))); // Drinks not expand by default
  const [availableProducts, setAvailableProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  
  useEffect(() => {
    setAvailableProducts(
      allProducts
        .filter(
          (product) => product.active
            && product.productTypeId === productType.id
            && !(originProducts.includes(product.name)),
        ),
    );
  }, [allProducts]);

  const handleAddButton = () => {
    setAddedProducts(addedProducts.concat(selectedProduct));
    setAvailableProducts(availableProducts.filter((product) => product.id !== selectedProduct.id));
    setSelectedProduct();
  };

  const selectChange = (event) => {
    const targetProduct = availableProducts.find((product) => product.id === +event.target.value);
    setSelectedProduct(targetProduct);
  };

  const handleExistingRemoveButton = (productId) => {
    setExistingProducts(existingProducts.filter((record) => record.product.id !== productId));
    setAvailableProducts(availableProducts
      .concat(allProducts.find((product) => product.id === productId))
      .sort((first, second) => first.id - second.id));
  };

  const handleAddedRemoveButton = (productId) => {
    // Remove from addedProducts list
    setAddedProducts(addedProducts.filter((product) => product.id !== productId));
    // Add to availableProducts list
    setAvailableProducts(availableProducts
      .concat(allProducts.find((product) => product.id === productId))
      .sort((first, second) => first.id - second.id));
  };

  return (
    <StyledProductType>
      <h2>{productType.name}</h2>
      {availableProducts.length > 0
      && (
        <StyledSelectionRow>
          <select onChange={selectChange}>
            <option value="0">Select</option>
            {availableProducts.map(((product) => (
              <option key={`availableProducts-${product.name}`} value={product.id}>{product.name}</option>
            )))}
          </select>
          <button
            type="button"
            onClick={handleAddButton}
            disabled={!selectedProduct || selectedProduct === '0'}
          >
            +
          </button>
        </StyledSelectionRow>
      )}
      <StyledProductHeader>
        <div>Product</div>
        <div>M</div>
        <div>S/D</div>
        <div>&#128161;</div>
      </StyledProductHeader>
      {existingProducts.map((record) => {
        const soldQuantity = record.product.sold[planWeekday];
        const deliveredQuantity = record.product.delivered[planWeekday];
        /*
        const suggestAmount = area
          ? Math.max(Math.ceil(soldQuantity * 1.2), 2)
          : deliveredQuantity;
        */
        // 08-12-2022, user asks to change the logic again
        const suggestAmount = deliveredQuantity;
        return (
          <StyledProductRow key={`existingProducts-${record.product.name}`}>
            <NewInputGroup
              productName={record.product.name}
              defaultValue={suggestAmount}
              productId={record.product.id}
              median={record.product.median[planWeekday]}
              lastWeekSituation={`${soldQuantity}/${deliveredQuantity}`}
            />
            <button type="button" onClick={() => handleExistingRemoveButton(record.product.id)}>X</button>
          </StyledProductRow>
        );
      })}
      {addedProducts.map((product) => (
        <StyledProductRow key={`addedProducts-${product.name}`}>
          <NewInputGroup
            productName={product.name}
            defaultValue={0}
            productId={product.id}
            median=""
            lastWeekSituation=""
          />
          <button type="button" onClick={() => handleAddedRemoveButton(product.id)}>X</button>
        </StyledProductRow>
      ))}
    </StyledProductType>
  );
};

const PlanForm = ({
  week, customerId, data, planWeekday, targetDate, setShowPlan,
}) => {
  const { productTypes, customers, products } = useSelector((state) => state);
  const dispatch = useDispatch();
  const customer = customers.find((i) => i.id === +customerId);
  const submitButtonClicked = async (event) => {
    event.preventDefault();
    setShowPlan(false);
    // const date = format(add(parse(week, 'I', new Date()), { weeks: 1, days: planWeekday }), 'yyyy-MM-dd');
    
    const date = targetDate;
    // Update customer's latest proproty in redux, this makes the 'plan' button disappears
    dispatch(modifyCustomer({ id: customer.id, latest: date }));
    for (let i = 0; i < Object.keys(event.target).length - 2; i += 1) {
      const quantityDelivery = +event.target[i].value;
      // Avoid add record that has quantity delivery === 0
      if (event.target[i].name && quantityDelivery > 0) {
        const product = products.find((j) => j.id === +event.target[i].id);
        const productId = +event.target[i].id;
        const quantitySold = customer.area ? 0 : +event.target[i].value;
        const invoiceAmount = customer.area ? 0 : product.unitPriceByOrder * +event.target[i].value;
        const invoiceTypeId = customer.area ? 1 : 2;

        const newRecord = {
          date,
          customerId: +customerId,
          productId,
          quantityDelivery,
          quantitySold,
          invoiceAmount,
          invoiceTypeId,
          soldAmount: invoiceAmount,
          deliveredAmount: customer.area
            ? product.unitPriceByDeliver * quantityDelivery
            : product.unitPriceByOrder * quantityDelivery,
        };
        // eslint-disable-next-line no-await-in-loop
        await addRecord(newRecord);
      }
    }
  };

  return (
    <StyledForm onSubmit={submitButtonClicked}>
      <button type="button" onClick={() => setShowPlan(false)}>X</button>
      <h1>{`${customer.name} ${targetDate} ${WEEKDAYS[planWeekday]}`}</h1>
      <StyledBody>
        {productTypes
          .filter(productType => productType.active) // Filtering active product types
          .map((productType) => (
          <ProductTypesDiv
            key={productType.name}
            productType={productType}
            data={data
              .slice(0, data.length - 3)
              .filter((record) => record.product.product_type.name === productType.name)}
            planWeekday={planWeekday}
            area={customer.area}
          />
        ))}
      </StyledBody>
      <StyledFormFooter>
        <button type="submit">Submit</button>
      </StyledFormFooter>
    </StyledForm>
  );
};

export default PlanForm;
