import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { StyledInputGroup } from './styles';

const InputGroup = ({
  id, type, name, text, min, step,
}) => (
  <StyledInputGroup>
    <Field id={id} type={type} name={name} min={min} step={step} />
    <label htmlFor={id}>{text}</label>
    <div className="bottomBorder" />
  </StyledInputGroup>
);

InputGroup.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  step: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

InputGroup.defaultProps = {
  type: 'text',
  min: '',
  step: '',
};

export default InputGroup;
