import styled from 'styled-components';
import { FadeIn } from './animations';

const StyledCustomers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 80px;

  & > .groupTable {
    width: 160px;

    & > .tableTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      & > div {
        font-size: 36px;
        font-weight: bold;
      };
      /* Add button */
      & > svg {
        transition: all .2s;

        &:hover {
          cursor: pointer;
          color: #1976D2;
          transition: all .2s;
        };
      };
    };

    & > .tableHeader {
      height: 48px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 160px;
      border-bottom: 4px solid lightgray;
      padding: 10px 0px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 8px;
      };
    };

    & > .tableBody {
      height: 735px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 4px solid lightgray;

      & > .row {
        border-bottom: 1px solid lightgray;
        display: grid;
        grid-template-columns: 160px;
        align-items: center;
        animation: ${FadeIn} 1s;

        &.editable:hover {
          cursor: pointer;
          color: #FFFFFF;
          background-color: #FDBC11;
          transition: all .2s;
        };

        & > div {
          height: 48px;
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: left;
          padding: 5px 0px 5px 8px;
          box-sizing: border-box;
        };
      };

      & > .row:nth-child(15) {
        border-bottom: none;
      };
    };

    & > .tableFooter {
      height: 48px;
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: right;

      & > .pageGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        & button {
          border: none;
          padding: 0px;
          background-color: transparent;
          text-shadow: 4px 4px 8px lightgray;
          color: #1976D2;
          font-size: 20px;
          transition: text-shadow .5s;

          &:not([disabled]):hover {
            cursor: pointer;
            color: #0D47A1;
            text-shadow: 2px 2px 8px lightgray;
            transition: text-shadow .5s;
          };

          &:disabled {
            color: gray;
          };
        };
      };
    };
  };

  & > .customerTable {
    width: 1200px;

    & > .tableTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;

      & > div {
        width: 200px;
        font-size: 36px;
        font-weight: bold;
      };
      /* Add button */
      & > svg {
        transition: all .2s;

        &:hover {
          cursor: pointer;
          color: #1976D2;
          transition: all .2s;
        };
      };
    };

    & > .tableHeader {
      height: 48px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 264px 160px 160px 24px 24px 24px 48px 40px 120px;
      border-bottom: 4px solid lightgray;
      grid-gap: 42px;
      align-items: center;
      padding: 10px 0px;
      font-size: 20px;
      font-weight: bold;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 8px;
      };
    };

    & > .tableBody {
      height: 735px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 4px solid lightgray;

      & > .row {
        border-bottom: 1px solid lightgray;
        display: grid;
        grid-template-columns: 264px 160px 160px 24px 24px 24px 48px 40px 120px;
        grid-gap: 42px;
        align-items: center;
        animation: ${FadeIn} 1s;

        &.editable:hover {
          cursor: pointer;
          color: #FFFFFF;
          background-color: #FDBC11;
          transition: all .2s;
        };

        & > div {
          height: 48px;
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: left;
          padding: 5px 0px 5px 8px;
          box-sizing: border-box;
        };
      };

      & > .row:nth-child(15) {
        border-bottom: none;
      };
    };

    & > .tableFooter {
      height: 56px;
      margin-top: 5px;
      display: grid;
      grid-template-columns: 264px 160px 160px 24px 24px 24px 48px 40px 120px;
      grid-gap: 42px;
      align-items: start;

      & > div {
        width: 100%;
        height: 100%;
      };

      & > div:first-child {
        width: 240px;
      };

      & > div:last-child {
        height: fit-content;
        justify-content: end;
      };

      & > .pageGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        & button {
          border: none;
          padding: 0px;
          background-color: transparent;
          text-shadow: 4px 4px 8px lightgray;
          color: #1976D2;
          font-size: 20px;
          transition: text-shadow .5s;

          &:not([disabled]):hover {
            cursor: pointer;
            color: #0D47A1;
            text-shadow: 2px 2px 8px lightgray;
            transition: text-shadow .5s;
          };

          &:disabled {
            color: gray;
          };
        };
      };
    };
  };
`;

export default StyledCustomers;
