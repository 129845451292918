import { createSlice } from '@reduxjs/toolkit';
import { getAllCustomers } from '../services/customers';

const customerSlice = createSlice({
  name: 'customers',
  initialState: [],
  reducers: {
    setCustomers(state, action) {
      return action.payload;
    },
    appendCustomer(state, action) {
      state.push(action.payload);
    },
    modifyCustomer(state, action) {
      return state
        .map((item) => (
          item.id === action.payload.id
            ? { ...item, ...action.payload }
            : item
        ));
    },
    removeCustomer(state, action) {
      return state
        .filter((customer) => customer.id !== action.payload.id);
    },
  },
});

export const {
  setCustomers, appendCustomer, modifyCustomer, removeCustomer,
} = customerSlice.actions;

export const initializeCustomers = () => async (dispatch) => {
  const customers = await getAllCustomers();
  dispatch(setCustomers(customers));
};

export default customerSlice.reducer;
