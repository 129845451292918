import { configureStore } from '@reduxjs/toolkit';

import {
  productTypeReducer,
  productReducer,
  invoiceTypeReducer,
  groupReducer,
  customerReducer,
  recordReducer,
  reportReducer,
  authReducer,
  userReducer,
  dashboardReducer,
  notificationReducer,
  modalReducer,
} from './reducers';

const store = configureStore({
  reducer: {
    productTypes: productTypeReducer,
    products: productReducer,
    invoiceTypes: invoiceTypeReducer,
    groups: groupReducer,
    customers: customerReducer,
    records: recordReducer,
    reports: reportReducer,
    auth: authReducer,
    users: userReducer,
    dashboard: dashboardReducer,
    notification: notificationReducer,
    modal: modalReducer,
  },
});

export default store;
