import { createSlice } from '@reduxjs/toolkit';
import {
  checkAuth,
  processLogin,
  processLogout,
} from '../services/auth';

const initialState = {
  status: 'unCheck',
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action) {
      return action.payload;
    },
    clearAuth() {
      return initialState;
    },
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export const checkLogin = () => async (dispatch) => {
  try {
    const user = await checkAuth();
    dispatch(setAuth({
      status: 'pass',
      user,
    }));
  } catch ({ response }) {
    dispatch(setAuth({
      status: 'fail',
      user: {},
    }));
  }
};

export const login = (loginData) => async (dispatch) => {
  try {
    const user = await processLogin(loginData);
    dispatch(setAuth({
      status: 'pass',
      user,
    }));
    return 'Login success!';
  } catch (error) {
    return error.response.data.error;
  }
};

export const logout = () => async (dispatch) => {
  await processLogout();
  dispatch(clearAuth());
};

export default authSlice.reducer;
