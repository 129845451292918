/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { setNotification, resetNotification } from '../reducers/notification';
import { closeModal } from '../reducers/modal';
import {
  user, group, customer, productType, product,
} from '../utils/modalHelper';
import { addUser, deleteUser, updateUser } from '../services/users';
import { appendUser, removeUser, modifyUser } from '../reducers/user';
import { addGroup, deleteGroup, updateGroup } from '../services/groups';
import { appendGroup, removeGroup, modifyGroup } from '../reducers/group';
import { addCustomer, deleteCustomer, updateCustomer } from '../services/customers';
import { appendCustomer, removeCustomer, modifyCustomer } from '../reducers/customer';
import { addProductType, updateProductType, deleteProductType } from '../services/productType';
import { appendProductType, modifyProductType, removeProductType } from '../reducers/productType';
import { addProduct, updateProduct, deleteProduct } from '../services/product';
import { appendProduct, modifyProduct, removeProduct } from '../reducers/product';
import InputGroup from './InputGroup';
import SelectGroup from './SelectGroup';
import ToggleGroup from './ToggleGroup';
import {
  ConfirmButton, UpdateButton, CancelButton, Loading, Modal as StyledModal,
} from './styles';

const Modal = () => {
  const dispatch = useDispatch();
  const { type, category, target } = useSelector((state) => state.modal);
  // 'type' is used for decide the layout of modal
  // 'category' is used for decide each function and initial values and validation schema

  const switchModal = () => {
    dispatch(closeModal());
  };

  // Assign initial values and functions according to category
  let valuesList;
  let validationSchema;
  let addFunction;
  let appendFunction;
  let deleteFunction;
  let removeFunction;
  let updateFunction;
  let modifyFunction;

  switch (category) {
    case 'user':
      valuesList = user(target).valuesList;
      validationSchema = user(target).validationSchema;
      addFunction = addUser;
      deleteFunction = deleteUser;
      updateFunction = updateUser;
      appendFunction = appendUser;
      removeFunction = removeUser;
      modifyFunction = modifyUser;
      break;
    case 'group':
      valuesList = group(target).valuesList;
      validationSchema = group(target).validationSchema;
      addFunction = addGroup;
      deleteFunction = deleteGroup;
      updateFunction = updateGroup;
      appendFunction = appendGroup;
      removeFunction = removeGroup;
      modifyFunction = modifyGroup;
      break;
    case 'customer':
      valuesList = customer(target).valuesList;
      validationSchema = customer(target).validationSchema;
      addFunction = addCustomer;
      deleteFunction = deleteCustomer;
      updateFunction = updateCustomer;
      appendFunction = appendCustomer;
      removeFunction = removeCustomer;
      modifyFunction = modifyCustomer;
      break;
    case 'type':
      valuesList = productType(target).valuesList;
      validationSchema = productType(target).validationSchema;
      addFunction = addProductType;
      deleteFunction = deleteProductType;
      updateFunction = updateProductType;
      appendFunction = appendProductType;
      removeFunction = removeProductType;
      modifyFunction = modifyProductType;
      break;
    case 'product':
      valuesList = product(target).valuesList;
      validationSchema = product(target).validationSchema;
      addFunction = addProduct;
      deleteFunction = deleteProduct;
      updateFunction = updateProduct;
      appendFunction = appendProduct;
      removeFunction = removeProduct;
      modifyFunction = modifyProduct;
      break;
    default:
      break;
  }

  const initialValues = {};

  valuesList.forEach((item) => {
    initialValues[item.name] = item.name === 'active'
      ? item.initialValue || false
      : item.initialValue || '';
  });

  const addButtonClicked = async (values, { setSubmitting, resetForm }) => {
    const newItem = { ...values };

    const response = await addFunction(newItem);
    setSubmitting(false);
    if (response.error) {
      resetForm();
      dispatch(setNotification({ type: 'error', content: response.error }));
    } else {
      switchModal();
      dispatch(appendFunction(response));
      dispatch(setNotification({ type: 'success', content: 'Success!' }));
    }

    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  const updateButtonClicked = async (values, { setSubmitting, resetForm }) => {
    const itemNewInfo = { id: target.id, ...values };

    const response = await updateFunction(itemNewInfo);
    setSubmitting(false);

    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
      resetForm();
    } else {
      dispatch(modifyFunction(itemNewInfo));
      switchModal();
      dispatch(setNotification({ type: 'success', content: response.message }));
    }

    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  const deleteButtonClicked = async () => {
    const response = await deleteFunction(target.id);
    switchModal();
    if (response.error) {
      dispatch(setNotification({ type: 'error', content: response.error }));
    } else {
      dispatch(removeFunction({ id: target.id }));
      dispatch(setNotification({ type: 'success', content: response.message }));
    }

    setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
  };

  window.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      switchModal();
    }
  });

  return (
    <StyledModal>
      <div role="presentation" className="modalBackground" onClick={switchModal} />
      <div className="modalWindow">
        <div className="modalHeaderContainer">
          <button type="button" onClick={switchModal}>
            X
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={type === 'add' ? addButtonClicked : updateButtonClicked}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="formBody">
                {valuesList.map((item) => {
                  if (item.select) {
                    return (
                      <div key={item.name}>
                        <SelectGroup
                          id={item.name}
                          name={item.name}
                          text={item.text}
                          options={item.options}
                        />
                      </div>
                    );
                  }

                  if (item.type === 'checkbox') {
                    return (
                      <div key={item.name}>
                        <ToggleGroup
                          id={item.name}
                          type={item.type}
                          name={item.name}
                          text={item.text}
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={item.name}>
                      <InputGroup
                        id={item.name}
                        type={item.type}
                        name={item.name}
                        text={item.text}
                        min={item.min}
                        step={item.step}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="formFooter">
                {type === 'add' && (
                <div className="buttonDiv">
                  <ConfirmButton type="submit" disabled={isSubmitting || !isValid || !dirty}>
                    {isSubmitting ? <Loading /> : 'Add'}
                  </ConfirmButton>
                </div>
                )}
                {type === 'edit' && (
                <>
                  <div className="buttonDiv">
                    <UpdateButton type="submit" disabled={isSubmitting || !isValid || !dirty}>
                      {isSubmitting ? <Loading /> : 'Update'}
                    </UpdateButton>
                  </div>
                  <DeleteIcon className="deleteButton" type="button" onClick={deleteButtonClicked} />
                  <div className="buttonDiv">
                    <CancelButton className="cancelButton" type="button" onClick={switchModal}>Cancel</CancelButton>
                  </div>
                </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </StyledModal>
  );
};

export default Modal;
