import styled from 'styled-components';
import { Rotate } from './animations';

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: " ";
    display: block;
    width: ${(props) => props.width || 32}px;
    height: ${(props) => props.width || 32}px;
    border-radius: 50%;
    border: 6px solid #FFFFFF;
    border-color: ${(props) => props.color || '#FFFFFF'} transparent ${(props) => props.color || '#FFFFFF'} transparent;
    animation: ${Rotate} 1s ease-out infinite;
  };
`;

export default Loading;
